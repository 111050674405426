import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  Text,
  useDisclosure
} from "@chakra-ui/core";
import Pluralize from "pluralize";
import React, { useContext } from "react";
import { AppContext, root_url } from "../../authentication/AppProvider";
import Rating from "../../data-display/Rating";
import Testimonial from "../../data-display/Testimonial";
import VideoPlayer from "../../data-display/VideoPlayer";
import { useMedia } from "../../external-pages/utils/hooks";
import CategoryGrid from "../CelebrityGrid";
import CelebrityVideo from "../CelebrityVideo";
import { useGetCategories } from "../hooks";
import { useHistory } from "react-router";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "920px"],
  mx: "auto",
  direction: ["column", "row"]
};
const mediaProps = {
  height: "100%",
  weight: "100%",
  maxHeight: "450px",
  minHeight: "450px",
  borderRadius: "0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const tagStyles = {
  size: "lg",
  bg: "gray.100",
  rounded: "full",
  _hover: { bg: "gray.200" },
  cursor: "pointer"
};

const videoContainerStyles = (isMobile, video) => ({
  position: isMobile ? "relative" : "absolute",
  zIndex: 1,
  px: [-5, 0],
  w: ["100%", "200px", "260px"],
  overflow: "hidden",
  rounded: ["none", "lg"],
  boxShadow: video ? "xl" : "none",
  h: ["360px", "420px"]
});

const videoWrapperStyles = {
  w: ["100%", "200px", "260px"],
  ml: [0, 0, 0, 0, 6],
  mr: [0, 5, 8]
};

const ProfilePage = ({
  nickname,
  picture: photo,
  video,
  profession,
  categories = [],
  description,
  reviews = [],
  related = [],
  latest_bookings = [],
  price,
  currency = "₦",
  rating = 4.5,
  toNextPage
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: categoryData } = useGetCategories();
  const { useAuthDisclosure } = useContext(AppContext);
  const { onOpen: onWishListOpen } = useAuthDisclosure();
  const picture = root_url + photo;

  let isMobile = useMedia("(max-width: 420px)");
  let history = useHistory();

  let celebCategories = (categoryData || []).filter(category =>
    categories.includes(categoryData.indexOf(category))
  );

  return (
    <Box>
      <Box position="relative">
        <Box bg="gray.50" pt={[0, 8]} pb={8} px={[0, 6]}>
          <Flex {...wrapperProps}>
            <Box {...videoWrapperStyles}>
              <Box {...videoContainerStyles(isMobile, video)}>
                {video ? (
                  <VideoPlayer {...mediaProps} url={video} playing />
                ) : (
                  <Image src={picture} {...mediaProps} />
                )}
              </Box>
            </Box>
            <Box px={[5, 0]} pt={[6, 0]} flex={1}>
              <Heading>{nickname}</Heading>
              <Text fontSize="xl">{profession}</Text>

              <Text my={5} opacity={0.8} lineHeight="tall">
                {description}
              </Text>

              {price && (
                <ButtonGroup size="lg" mt={6} spacing={[3, 3, 6]}>
                  <Button
                    onClick={toNextPage}
                    fontSize="md"
                    variantColor="pink"
                    variant="solid"
                  >
                    Book now for {currency + price.toLocaleString()}
                  </Button>

                  <Button
                    display={["none", "none", "inline-block"]}
                    fontSize="md"
                    variantColor="pink"
                    onClick={() =>
                      onWishListOpen(
                        "login",
                        <WishListHeader {...{ nickname, picture }} />
                      )
                    }
                    leftIcon="heart"
                    variant="link"
                  >
                    Wishlist
                  </Button>

                  <IconButton
                    variant="outline"
                    variantColor="pink"
                    onClick={() =>
                      onWishListOpen(
                        "login",
                        <WishListHeader {...{ nickname, picture }} />
                      )
                    }
                    display={["inline-block", "inline-block", "none"]}
                    size="lg"
                    icon="heart"
                  />
                </ButtonGroup>
              )}

              {/* <Stack mt={6} isInline align="center">
                <Icon name="delete" />
                <Text>Typically responds in 2 hours</Text>
              </Stack> */}
            </Box>
          </Flex>
        </Box>
        <Box px={[5, 6]} py={[2, 8]}>
          <Flex {...wrapperProps}>
            <Box w={["100%", "200px", "300px"]} mr={[0, 5]} />
            <Box flex={1}>
              {reviews && reviews.length > 0 && (
                <Flex
                  direction={["column", "column", "column", "column", "row"]}
                >
                  <Box flex={1}>
                    <Text fontSize="lg" lineHeight="none" fontWeight="bold">
                      {Pluralize("review", reviews.length, true)}
                    </Text>
                    <Stack isInline align="center" spacing={4}>
                      <Rating size="lg" value={1} isReadOnly />
                      <Text fontSize="lg" mt={2}>
                        {rating} stars
                      </Text>
                    </Stack>
                    {reviews.length > 0 && (
                      <Box
                        mt={[5, 3]}
                        d={["block", "block", "block", "block", "none"]}
                      >
                        {reviews.slice(0, 1).map((review, index) => (
                          <Testimonial
                            {...review}
                            as="i"
                            key={index}
                            mb={[5, 3]}
                          />
                        ))}
                      </Box>
                    )}
                    {reviews.length > 1 && (
                      <Button
                        onClick={() => onOpen()}
                        variant="link"
                        variantColor="pink"
                      >
                        See all reviews
                      </Button>
                    )}
                  </Box>
                  <Box flex={1} d={["none", "none", "none", "none", "block"]}>
                    {reviews.slice(0, 1).map((review, index) => (
                      <Testimonial {...review} as="i" key={index} mb={5} />
                    ))}
                  </Box>
                </Flex>
              )}

              {celebCategories && (
                <Stack mt={5} isInline align="center">
                  {celebCategories.slice(0, 3).map((category, index) => (
                    <Tag
                      key={index}
                      onClick={() => history.push(`/c/${category.slug}`)}
                      {...tagStyles}
                    >
                      <TagLabel textTransform="capitalize">
                        {category.label}
                      </TagLabel>
                    </Tag>
                  ))}
                </Stack>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
      {latest_bookings && latest_bookings.length > 1 && (
        <LatestBookings {...{ latest_bookings }} />
      )}

      {related && related.length > 0 && <SimilarCelebs {...{ related }} />}

      <TestimonialModal {...{ isOpen, reviews, onClose }} />
    </Box>
  );
};

export default ProfilePage;

const LatestBookings = ({ latest_bookings = [] }) => {
  return (
    <Box pt={[20, 12, 24]} px={[5, 6]} {...wrapperProps}>
      <Heading size="lg" mb={6}>
        Latest videos
      </Heading>
      <Grid
        templateColumns={["none", "repeat(auto-fill, minmax(232px, 1fr))"]}
        rowGap={8}
        columnGap={8}
        w="100%"
      >
        {latest_bookings.slice(0, 6).map(booking => (
          <CelebrityVideo {...booking} key={booking.uuid} />
        ))}
      </Grid>
    </Box>
  );
};

export const SimilarCelebs = ({ related }) => {
  return (
    <Box py={[16, 24]} px={[5, 6]} {...wrapperProps}>
      <Heading size="lg" mb={6}>
        Similar celebs
      </Heading>

      <CategoryGrid
        data={related}
        hasData={related.length > 0}
        dummyArray={[1, 2, 3, 4]}
      />
    </Box>
  );
};

const TestimonialModal = ({ isOpen, reviews, onClose }) => {
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent rounded="md">
        <ModalHeader borderBottomWidth="2xs">
          {Pluralize("Review", reviews.length, true)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {reviews.map(x => (
            <Testimonial key={x} py={5} />
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const WishListHeader = ({ nickname, picture }) => {
  return (
    <Stack isInline align="center">
      <Avatar src={picture} name={nickname} />
      <Box>
        <Text fontWeight="medium" lineHeight="short">
          {nickname}
        </Text>
        <Text fontSize="sm" fontWeight="normal">
          Login to add to wishlist
        </Text>
      </Box>
    </Stack>
  );
};
