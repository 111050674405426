import React, { useContext } from "react";
import { AppContext } from "./authentication/AppProvider";
import AuthModal from "./authentication/AuthModal";
import BookingFlow from "./route-flows/bookingRoute";

export default function App() {
  const { useAuthDisclosure } = useContext(AppContext);
  const { isOpen, onClose, headerElem, isLogin } = useAuthDisclosure();

  return (
    <div className="App">
      <BookingFlow />

      <AuthModal {...{ isOpen, onClose, headerElem, isLogin }} />
    </div>
  );
}
