import React from "react";
import { Box, Flex, Stack, Avatar, Text } from "@chakra-ui/core";
import VideoPlayer from "../data-display/VideoPlayer";
import Rating from "../data-display/Rating";

const mediaProps = {
  height: "100%",
  weight: "105%",
  maxHeight: "420px",
  minHeight: "420px",
  borderRadius: "16px 16px 0px 0px",
  display: "block",
  position: "relative",
  visibility: "initial",
  objectFit: "cover"
};

const videoWrapper = {
  height: "420px",
  direction: "column",
  borderWidth: "2xs",
  borderColor: "gray.100",
  boxShadow: "md",
  borderRadius: "16px",
  overflow: "hidden",
  bg: "gray.50"
};

const CelebrityVideo = ({ order_from, order_rating, video }) => {
  return (
    <Flex {...videoWrapper}>
      <Box flex={1} overflow="hidden" borderRadius="16px 16px 0px 0px">
        <VideoPlayer {...mediaProps} url={video.recording_url} />
      </Box>

      <Stack
        py={4}
        spacing={3}
        px={6}
        isInline
        align="center"
        justify="space-between"
        borderTopWidth="2xs"
      >
        <Text fontSize="md" isTruncated lineHeight="none">
          By {order_from}
        </Text>
        {order_rating && <Rating size="sm" value={order_rating} />}
      </Stack>
    </Flex>
  );
};

export default CelebrityVideo;
