import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack
} from "@chakra-ui/core";
import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { boolean, object, string } from "yup";
import { Redirect } from "react-router-dom";
import PasswordInput from "../form-elements/PasswordInput";
import { AppContext } from "./AppProvider";

let initialValue = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  subscription: true
};

let validationSchema = object().shape({
  firstName: string()
    .trim()
    .required("First name is required"),
  lastName: string()
    .trim()
    .required("Last name is required"),
  username: string()
    .trim()
    .required("Username is required"),
  email: string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: string().required("Enter a password"),

  subscription: boolean()
});

let placeholders = {
  firstName: {
    label: "First name",
    placeholder: "Your first name"
  },
  lastName: {
    label: "Last name",
    placeholder: "Your last name"
  },
  username: {
    label: "Username",
    placeholder: "Enter a username"
  },
  email: {
    label: "Email address",
    placeholder: "Your email"
  },
  password: {
    label: "Password",
    placeholder: "Type a secure password"
  },
  subscription: {
    label: "Send me exclusive offers and discounts."
  }
};

let commonProps = ({ name }, { touched, errors }) => ({
  ...placeholders[name],
  isInvalid: Boolean(touched[name] && errors[name]),
  errorMessage: errors[name]
});

const SignupForm = ({ value, onSubmit, spacing = "24px" }) => {
  let [loading, setLoading] = React.useState(false);
  const { setAuthToken, isLoggedIn } = useContext(AppContext);

  function onFormSubmit(data) {
    setLoading(true);
    onSubmit(data).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...initialValue, ...value }}
      onSubmit={values => {
        let email = values.email.toLowerCase();
        let newValues = { ...values, email };
        onFormSubmit(newValues);
      }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={spacing}>
              <Flex direction={["column", "row"]} justify="space-between">
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={commonProps(field, form).isInvalid}
                    >
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="pink.400"
                        fontSize="md"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box size={spacing} />

                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      flex={1}
                      isInvalid={commonProps(field, form).isInvalid}
                    >
                      <FormLabel>{commonProps(field, form).label}</FormLabel>
                      <Input
                        {...field}
                        fontSize="md"
                        focusBorderColor="pink.400"
                        placeholder={commonProps(field, form).placeholder}
                      />
                      <FormErrorMessage>
                        {commonProps(field, form).errorMessage}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="username">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <Input
                      {...field}
                      type="email"
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box />

              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={commonProps(field, form).isInvalid}>
                    <FormLabel>{commonProps(field, form).label}</FormLabel>
                    <PasswordInput
                      {...field}
                      fontSize="md"
                      focusBorderColor="pink.400"
                      placeholder={commonProps(field, form).placeholder}
                    />
                    <FormErrorMessage>
                      {commonProps(field, form).errorMessage}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Box />

              <Field name="subscription">
                {({ field, form }) => (
                  <Checkbox
                    {...field}
                    {...commonProps(field, form)}
                    variantColor="pink"
                    isChecked={field.value}
                  >
                    {placeholders[field.name].label}
                  </Checkbox>
                )}
              </Field>
            </Stack>

            <Button
              mt={spacing}
              type="submit"
              size="lg"
              isFullWidth
              variantColor="pink"
              isLoading={loading}
              // isDisabled={!isComplete}
            >
              Sign up
            </Button>
          </form>
        );
      }}
    />
  );
};

SignupForm.defaultProps = {
  onSubmit: data => alert(JSON.stringify(data))
};

export default SignupForm;
