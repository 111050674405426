const keycodes = {
  _ENTER: 13,
  _TAB: 9,
  _LEFT: 37,
  _UP: 38,
  _DOWN: 40,
  _RIGHT: 39,
  _SPACE: 32,
  _ESCAPE: 27
};

export { keycodes };
