import { Heading, Box, Text } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const Terms = props => {
  return (
    <PageWrapper>
      <Box bg="gray.50" minH="640px">
        <Box {...wrapperProps}>
          <Heading>Terms of Use</Heading>
          <br />
          <Text fontSize="14px" color="black">
            This Site Terms of Use Agreement (<strong>“Terms"</strong>) governs
            your use of the PENG (<strong>“The Company”</strong>) marketplace
            platform for personalized video shout-outs and other products and
            services offered by PENG Corporate Resource Limited through our
            website (sendpeng.com), mobile application (“App”), and through the
            services we provide (collectively, the website, App, and services
            referred to as our “Site”). “You” refers to you as a user of our
            Site.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            These Terms apply to visitors to and users of our Site. Any use by
            you of our Site as a Talent User (defined below) will be governed by
            the Talent Terms of Service.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>PLEASE READ THESE TERMS CAREFULLY.</strong> By using our
            Site or otherwise indicating your acceptance (for example, agreeing
            by creating or when logging into your account, clicking “I Agree,”
            etc.), you represent and warrant that you have read, understand, and
            agree to be bound by these Terms. If you do not agree to be bound by
            these Terms, you may not access or use our Site.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>1. PENG Videos</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a.</strong> Through our Site, you have the opportunity to
            purchase or personalized video shout-outs (“PENG Videos”) from
            comedians, actors, performers, and influencers (each a “Talent
            User”). You may submit a request for a PENG Video to a Talent User
            through our Site personalized for you or a third party that that you
            identify as a recipient (“Recipient”). In submitting a request,
            request, you agree not to use a false identity or provide false
            information. You agree not to use our Site if you have previously
            been removed or banned from our Site.
            <br />
            <br />
            <strong>b.</strong> You acknowledge and agree that the Talent User
            has sole discretion to determine how to fulfill your request and
            over the content of the PENG Video created (and may not follow your
            request exactly). The Talent User has up to seven days (at our sole
            discretion) to fulfill or decline your request. Once your request is
            fulfilled by a Talent User, your payment account will be charged the
            amount specified on the Talent User’s booking page on our Site at
            the time you made your request. You may not return or exchange a
            PENG Video and no refunds will be issued. We reserve the right to
            reject any request in our sole discretion.
            <br />
            <br />
            <strong>c.</strong> Subject to your payment in full for each PENG
            Video you request, the Talent User hereby grants to you certain
            rights to use the PENG Video solely for your own personal,
            non-commercial, and non-promotional purposes, subject to these
            Terms: a non-exclusive, royalty-free, fully paid, worldwide,
            sublicensable, revocable license to use, reproduce, distribute, and
            publicly display that PENG Video, in any and all media, whether now
            known or hereafter invented (for example, on social media
            platforms). PENG Videos are licensed and are not sold. You may not
            sell, re-sell, or encumber your rights in any PENG Video. You may
            sublicense your rights in a PENG Video only to the extent necessary
            for you to use the PENG Video as permitted under these Terms (for
            example, sharing it with friends on a social media platform, sending
            it to a Recipient for personal, non-commercial, and non-promotional
            purposes subject to these Terms). You may use a PENG Video only in
            accordance with these Terms, including the acceptable use
            restrictions in Section 6. We may terminate all or part of the
            foregoing licenses at any time for any reason. We reserve the right
            to remove a PENG Video from our Site at any time for any reason
            without any notice to you.
            <br />
            <br />
            <strong>d.</strong> You acknowledge and agree that you have no
            expectation of privacy with respect to any PENG Video requested by
            you or any Submission (defined below) you make, and that you will
            not make any request or Submission that infringes on the privacy or
            other rights of a third party. You acknowledge that the PENG
            watermark on each PENG Video must remain intact and you agree not to
            edit, change, modify, or remove the watermark from any PENG Video or
            assist or encourage any third party to do so. You further agree not
            to edit, change, modify, or create any derivative work of a PENG
            Video or assist or encourage any third party to do so. If you breach
            any provisions of these Terms, your license to use the PENG Video
            granted under Section 1(c) terminates and you must promptly remove
            all copies of the PENG Video in your possession or control,
            including on any social media platform, notify any Recipient of the
            termination and need to remove all copies, and take any other action
            we reasonably request to assist in locating and removing the PENG
            Video, including identifying each Recipient.
            <br />
            <br />
            <strong>e.</strong> You may create an account on our Site. In
            creating an account, you agree not to use a false identity or
            provide false information. You agree not to create an account if you
            have previously been removed or banned from our Site. If we
            reasonably believe that you have done so, we have the right to
            suspend or terminate your account and your use of any part of our
            Site. You are responsible for maintaining the confidentiality of
            your Site account information, including your username and password.
            You are responsible for all activities that occur on or in
            connection with your Site account and you agree to notify us
            immediately of any unauthorized access or use of your Site account.
            You acknowledge and agree that we are not responsible or liable for
            any damages, losses, costs, expenses, or liabilities related to any
            unauthorized access to or use of your Site account.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>2. Additional Terms</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Some products or services offered through the Site may have
            additional terms and conditions (<strong>“Additional Terms”</strong>
            ). If Additional Terms apply, we will make them available for you to
            read in connection with that product or service. By using that
            product or service, you agree to the Additional Terms. To the extent
            that the Additional Terms conflict with any of these Terms, these
            Terms will govern unless otherwise specified in such Additional
            Terms.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>3. Eligibility</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a. Age: </strong> You must be at least 16 years old to use
            our Site.
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>b. Representations and Warranties: </strong> You represent
            and warrant that: <br />
            i. you have not been prohibited from using or accessing any aspect
            of our Site by us or under to any applicable law or regulation;
            <br />
            ii. you will comply with all of the terms and conditions, as revised
            from time to time, of any third party payment provider selected by
            us, and you are not on a prohibited list of that third party payment
            provider;
            <br />
            iii. we have not previously disabled any Site account you created or
            controlled for violation of any law or regulation, the infringement
            of any third party right, or the violation of any of these Terms;
            and
            <br />
            iv. you are not a convicted sex offender.
            <br />
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>4. Fees and Payment</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a. Fees: </strong> The fee for a PENG Video is specified on
            the Talent User’s booking page on our Site at the time you make your
            request. You agree to pay all amounts due in accordance with the
            payment terms in effect when you submit your request.
            <br />
            <br />
            <strong>b. Payment: </strong> You may request a PENG Video either by
            using a valid debit card through the third party payment provider on
            www.sendpeng.com.
            <br />
            i. For each PENG Video requested on www.sendpeng.com, you must
            provide our third party payment provider with a valid credit or
            debit card (Visa, MasterCard, or any other issuer accepted by the
            payment provider). Your payment provider agreement governs your use
            of your credit or debit card, and you should refer to that agreement
            and not to these Terms to determine your rights and liabilities. You
            acknowledge and agree that PENG does not operate, own or control any
            payment provider used by PENG, and your use of any such payment
            provider is subject to the terms and conditions and privacy policies
            of that payment provider. You also agree to immediately notify the
            payment provider of any change in your billing address (or other
            information) for the credit or debit card account you use for
            payment. By providing your debit or credit card number and
            associated payment information, you agree that PENG may place a
            pre-authorization hold on your card and, after your request has been
            fulfilled, authorize the payment provider to immediately charge you
            for all amounts due and payable with no notice to or consent from
            you.
            <br />
            ii. On www.sendpeng.com, you may choose to tip the Talent User who
            fulfilled your request. You acknowledge that PENG does not mandate
            any such tip or gratuity; however, if you do choose to do so, the
            amount must be at least N1,000. You hereby agree to pay the amount
            you authorize as a tip for the applicable Talent User. PENG reserves
            the right (but is under no obligation) to cancel your PENG Video
            request if: (x) your payment method is declined; (y) your Wallet
            does not have enough Credits stored for the PENG Video from the
            Talent User you have selected; or (z) you have previously been
            banned or removed from our Site for any reason. PENG also reserves
            the right at any time to change its fees and payment procedures,
            including its payment provider and terms relating to Credits, either
            immediately upon posting on our Site or by other notice to you.
            <br />
            <br />
            <strong>c. Payment Questions: </strong> If you have a question about
            a charge to your credit or debit card please promptly contact us at
            hello@sendpeng.com. We have the sole discretion to determine how
            billing disputes will be resolved. You agree to comply with the
            terms of the third party payment provider selected by us, which is
            subject to change at our discretion.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>5. Ownership</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            <strong>a. </strong>You acknowledge and agree that each PENG Video
            is owned by the Talent User who created it.
            <br />
            <br />
            <strong>b. </strong> We or our licensors own all right, title, and
            interest in and to: (i) our Site and the “look and feel” of our
            Site, including all software, ideas, processes, data, text, media,
            and other content available on our Site (
            <strong>individually, and collectively, “PENG Content”</strong>);
            and (ii) our trademarks, logos, and brand elements (“Marks”). Our
            Site, PENG Content, and Marks, are each protected under Nigerian
            laws. You may not duplicate, copy, or reuse any portion of the
            HTML/CSS, JavaScript, visual design elements, or concepts without
            our prior express written consent.
            <br />
            <br />
            <strong>c. </strong> You hereby grant to us a non-exclusive,
            royalty-free, fully paid, unlimited, worldwide, sublicensable
            (through multiple tiers of sublicenses), perpetual, and irrevocable
            license, in any and all manner and media, whether now known or
            hereinafter invented (including social media platforms), to use,
            reproduce, license, distribute, modify, adapt, reformat, publicly
            perform, publicly display, create derivative works of, and otherwise
            use the following for the purposes of operating and providing our
            Site, developing and improving our products and services, and
            advertising, marketing, and promoting our Site and our products and
            services: (i) any request (video, text, or otherwise) that you make
            to any Talent User, including information concerning any Recipient;
            and (ii) any submission that you make to PENG, whether through our
            Site, a social media platform, or third party website, or otherwise,
            including a reaction video, idea, intellectual property, publicity
            rights, Feedback (defined below), review, photo, video, email, text,
            post, or other communication, whether relating to you, or a third
            party ((i) and (ii) each, individually, and collectively, a
            “Submission”). You represent and warrant that you either: (i) own
            all rights to any Submission; or (ii) have all rights necessary,
            including with respect to any third party that contributed to, is
            included in, or is referred to in any Submission, to grant to us the
            foregoing rights. You will not make any Submission that is
            confidential or proprietary and no Submission will contain or
            include any information that you do not have the right to disclose
            or that you or any Recipient do not wish to be disclosed. PENG will
            not be responsible or liable for any use or disclosure of a
            Submission, including any personal information of, or belonging to,
            you or any third party.
            <br />
            <br />
            <strong>d. </strong>We may, for any reason, refuse to accept or
            transmit a Submission or refuse to remove a Submission from our
            Site. Further, we reserve the right to decide whether a Submission
            violates these Terms and may, at any time, without notice to you and
            in our sole discretion, remove your Submission, block you from our
            Site, terminate your access, or take other appropriate action for
            violation of these Terms.
            <br />
            <br />
            <strong>e. </strong>PENG desires to avoid the possibility of future
            misunderstandings when projects developed by us, our employees, or
            our contractors might seem to be similar to your Submission. If your
            Submission consists of any ideas, suggestions, proposals, plans, or
            other materials related to our business (individually, and
            collectively, “Feedback”), you acknowledge and agree that you are
            submitting that Feedback at your own risk and that PENG has no
            obligation (
            <strong>
              including no obligation of confidentiality or privacy
            </strong>
            ) with respect to that Feedback, and you grant to PENG a
            non-exclusive, royalty-free, fully paid, unlimited, worldwide,
            sublicensable (through multiple tiers of sublicenses), perpetual,
            and irrevocable license, in any and all manner and media, whether
            now known or hereinafter invented, to reproduce, license,
            distribute, modify, adapt, publicly perform, publicly display,
            create derivative works of (for example, translations, adaptations,
            or other changes), and otherwise use and exploit in any manner
            (including commercially), any and all Feedback.
            <br />
            <br />
            <strong>f. </strong>You hereby waive any moral rights you may have
            in any Submission, including Feedback
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>6. Acceptable Use of our Site</strong>
          </Text>
          <Text fontSize="14px" color="black">
            <br />
            <strong>a. Your Responsibilities:</strong> You are responsible for
            your use of any PENG Video, your Site account, our Site, and any
            Submission. Our goal is to create a positive, useful, and safe user
            experience. To promote this goal, we prohibit certain kinds of
            conduct that may be harmful. You represent and warrant that: (i) you
            will not use or authorize the use of any PENG Video for any purposes
            other than the personal, non-commercial, and non-promotional
            purposes set forth in Section 1(c) and (ii) in using a PENG Video,
            your Site account, and our Site, and in making any Submission, you
            will not:
            <br />
            i. violate any law, regulation or court order;
            <br />
            ii. violate, infringe, or misappropriate the intellectual property,
            privacy, publicity, moral, or other legal rights of any third party;
            <br />
            iii. take any action that is or submit, post, share, or communicate
            anything that is or that incites or encourages action that is,
            explicitly or implicitly: illegal, abusive, harassing, threatening,
            hateful, racist, derogatory, harmful to any reputation,
            pornographic, indecent, profane, obscene, or otherwise objectionable
            (including nudity);
            <br />
            iv. send advertising or commercial communications, including spam,
            or any other unsolicited or unauthorized communications;
            <br />
            v. engage in spidering or harvesting, or participate in the use of
            software, including spyware, designed to collect data from our Site,
            including from any user of our Site;
            <br />
            vi. transmit any virus, other computer instruction, or technological
            means intended to, or that may, disrupt, damage, or interfere with
            the use of computers or related systems;
            <br />
            vii. stalk, harass, threaten, or harm any third party;
            <br />
            viii. impersonate any third party;
            <br />
            ix. participate in any fraudulent or illegal activity, including
            phishing, money laundering, or fraud;
            <br />
            x. use any means to scrape or crawl any part of our Site;
            <br />
            xi. attempt to circumvent any technological measure implemented by
            us, any of our providers, or any other third party (including
            another user) to protect us, our Site, users, Recipients, or any
            other third party;
            <br />
            xii. access our Site to obtain information to build a similar or
            competitive website, application, or service;
            <br />
            xiii. attempt to decipher, decompile, disassemble, or reverse
            engineer any of the software or other underlying code used to
            provide our Site; or
            <br />
            xiv. advocate, encourage, or assist any third party in doing any of
            the foregoing.
            <br />
            <br />
            For clarity, your use of any PENG Video includes use anywhere (on
            our Site or otherwise).
            <br />
            <br />
            <strong>b. Investigations: </strong> You acknowledge and agree that
            we are not obligated to monitor access to or use of our Site by you
            or third parties (including monitoring Submissions), but we have the
            right to do so in order to operate our Site, to ensure compliance
            with these Terms, to comply with applicable law, regulation, court
            order, or other legal, administrative, or regulatory request or
            process, or otherwise.
            <br />
            <br />
            Without limiting any of our rights, you acknowledge and agree that
            any request you submit for a PENG Video may be rejected by us or by
            a Talent User; if that happens more than once, we may block you from
            our Site, terminate your access, or take other appropriate action.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>7. Links </strong>
          </Text>
          <Text fontSize="14px" color="black">
            <br />
            Our Site may contain links to social media platforms or third party
            websites. You acknowledge and agree that: (a) the link does not mean
            that we endorse or are affiliated with the platform or website; and
            (b) we are not responsible or liable for any damages, losses, costs,
            expenses, or liabilities related to your use of the platform or
            website. You should always read the terms and conditions and privacy
            policy of the platform or website before using it.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>8. Changes to our Site </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            We enhance and update our Site often. You acknowledge and agree we
            may change or discontinue any aspect of our Site at any time, with
            or without notice to you.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>9. Termination and Reservation of Rights </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            You may stop using our Site at any time. You may cancel your Site
            account at any time by contacting us at hello@snedpeng.com. We
            reserve the right to terminate access to our Site to any person,
            including you, at any time, for any reason, in our sole discretion.
            If you violate any of these Terms, your permission to use our Site
            automatically terminates.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>10. Indemnification </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            You agree to indemnify,defend, and hold harmless PENG and its
            parents, subsidiaries, affiliates, officers, employees, directors,
            shareholders, agents, partners and licensors (each, a “PENG Party,”
            and collectively, “PENG Parties”) from and against any and all
            claims, costs, proceedings, demands, losses, damages, and expenses
            (including reasonable attorneys’ fees and costs) of any kind or
            nature arising from, out of, in connection with, or relating to: (a)
            any actual or alleged breach of these Terms by you or anyone using
            your Site account; (b) any actual or alleged violation of any laws
            or regulations or infringement of any rights of any third party by
            you or anyone using your Site account; (c) your negligence,
            misconduct, or fraud; (d) any Submission; (e) any action or inaction
            by you or anyone acting on your behalf; or (f) to the fullest extent
            permitted by applicable law, relating to your use of our Site. PENG
            will have the right to select counsel to be paid for by you in
            connection with any indemnification by you. You will reasonably
            cooperate with us in connection with any indemnification matters.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>11. Disclaimers and Limitations on our Liability </strong>
          </Text>
          <Text fontSize="14px" color="black">
            <br />
            a. You acknowledge and agree that your use of our Site is at your
            own risk and that our Site is provided on an “as is” and “as
            available” basis. To the extent permitted by applicable law, the
            PENG Parties disclaim all warranties, conditions, and
            representations of any kind, whether express, implied, statutory, or
            otherwise, including those related to merchantability, fitness for a
            particular purpose, and non-infringement and those arising out of
            course of dealing or usage of trade.
            <br />
            b. In particular, the PENG Parties make no representations or
            warranties about the accuracy or completeness of content available
            on or through our Site or the content of any social media platform
            or third party website linked to or integrated with our Site. You
            acknowledge and agree that the PENG Parties will have no liability
            for any: (i) errors, mistakes, or inaccuracies of content; (ii)
            personal injury, property damage, or other harm resulting from your
            access to or use of our Site; (iii) any unauthorized access to or
            use of our servers, any personal information, or user data; (iv) any
            interruption of transmission to or from our Site; (v) any bugs,
            viruses, trojan horses, or the like that may be transmitted on or
            through our Site; or (vi) any damages, losses, costs, expenses, or
            liabilities of any kind incurred as a result of the use of any
            content posted or shared through our Site.
            <br />
            c. You acknowledge and agree that any material or information
            downloaded or otherwise obtained through our Site, including any
            PENG Video, is done at your own risk and that you will be solely
            responsible for any damages, losses, costs, expenses, or liabilities
            arising from or in connection with doing so. No advice or
            information, whether oral or written, obtained by you from us or
            through our Site, including through a PENG Video, will create any
            warranty not expressly made by us.
            <br />
            d. You acknowledge and agree that when using our Site, you will be
            exposed to content from a variety of sources, and that PENG is not
            responsible for the accuracy, usefulness, safety, legality, or
            intellectual property rights of or relating to any such content. You
            further understand and acknowledge that you may be exposed to
            content that is inaccurate, offensive, indecent, objectionable, or
            harassing, and you agree to waive, and hereby do waive, any legal or
            equitable rights or remedies you have or may have against any PENG
            Party with respect thereto.
            <br />
            e. To the fullest extent permitted by applicable law, you
            acknowledge and agree that in no event will any PENG Party be liable
            to you or to any third party for any indirect, special, incidental,
            punitive, or consequential damages (including for loss of profits,
            revenue, or data) or for the cost of obtaining substitute products,
            arising out of or in connection with these Terms, however caused,
            whether such liability arises from any claim based upon contract,
            warranty, tort (including negligence), strict liability or
            otherwise, and whether or not PENG has been advised of the
            possibility of such damages.
            <br />
            f. To the maximum extent permitted by applicable law, our total
            cumulative liability to you or any third party under these Terms,
            from all causes of action and all theories of liability, will be
            limited to and will not exceed the fees actually received by PENG
            from you during the 12 months preceding the claim giving rise to
            such liability.
            <br />
            g. Certain jurisdiction does not allow the exclusion or limitation
            of certain damages. If those laws apply to you, some or all of the
            above exclusions or limitations may not apply to you, and you might
            have additional rights.
            <br />
            h. You agree that the limitations of damages set forth above are
            fundamental elements of the basis of the bargain between PENG and
            you.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>12. Other Provisions </strong>
          </Text>
          <Text fontSize="14px" color="black">
            <br />
            a. Force Majeure: Under no circumstances will any PENG Party be
            liable for any delay or failure in performance due in whole or in
            part to any acts of God, earthquakes, unavoidable accidents, laws,
            rules, regulations or orders of government authorities, acts of war
            (declared or not), terrorism, hostilities, blockades, civil
            disturbances, embargoes, strikes, or any other event or cause beyond
            the reasonable control of any PENG Party.
            <br />
            b. Choice of Law and Jurisdiction: These Terms will be governed by
            and construed in accordance with the laws of the Federal Republic of
            Nigeria, without giving effect to any conflict of laws rules or
            provisions. You consent and submit to the personal jurisdiction of
            such courts for the purposes of any such action.
            <br />
            c. Severability: If any provision of these Terms is found to be
            invalid or unenforceable, that provision will be deemed
            appropriately modified to give effect to the intent of the provision
            or, if modification is not possible, will be severed from these
            Terms and will not affect the enforceability of any other provision.
            <br />
            d. No Waiver or Amendment: The failure by PENG to enforce any right
            or provision of these Terms will not prevent PENG from enforcing
            such right or provision in the future and will not be deemed to
            modify these Terms.
            <br />
            e. Assignment: PENG may, at any time, assign its rights and
            obligations under these Terms, including in connection with a sale
            of assets, merger, acquisition, reorganization, bankruptcy, other
            transaction, or by operation of law.
            <br />
            f. Miscellaneous: The term “including” in these Terms will be
            interpreted broadly and will mean “including, without limitation.”
            Titles are for convenience only and will not be considered when
            interpreting these Terms.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>13. Changes to these Terms </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            From time to time, we may change these Terms. If we change these
            Terms, we will give you notice by posting the revised Terms on our
            Site and updating the “Last Updated” date at the top of these Terms.
            The revised Terms will be effective immediately if you are a new
            user of our Site and will be effective 30 days after posting if you
            are an existing registered user unless you accept the revised Terms
            before that time. By continuing to use our Site, you agree to the
            revised Terms.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text fontSize="12px" color="black">
            Last Updated: 4th November, 2019
          </Text>
          <br />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Terms;
