import React, { createContext, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const hostname = window && window.location && window.location.hostname;
let backendHost = '';
if(hostname === 'sendpeng.com') {
  backendHost = 'https://api.sendpeng.com';
} else if(hostname === 'staging.sendpeng.com') {
  backendHost = 'https://apistaging.sendpeng.com';
} else/* if(/^(ngrok|csb)/.test(hostname)) */{
  backendHost = `https://002301f7.ngrok.io`;
} /*else {
  backendHost = 'http://localhost:8000';
}*/

export const root_url = backendHost;
let base_url = `${root_url}/api/v1`;

export const createUrl = (route, query) => {
  const baseUrl = base_url + route;
  return query ? `${baseUrl}/${query}` : baseUrl;
};

const getUserURL = `${root_url}/api/v1/auth/user`;
const logOutURL = `${root_url}/api/v1/rest-auth/logout/`;

const AppContext = createContext({
  useAuthDisclosure: () => {}
});

const AppProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("token") || ""
  );
  const [user, setUser] = useState(null);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [headerElem, setHeaderElem] = useState("");

  // Setup config with token - helper function

  const tokenConfig = authToken => {
    // Get token from state
    const token = authToken || localStorage.getItem("token");
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    // If token, add to headers config
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
  };

  ////////////////////

  const fetcher = (url, config = {}, ignoreError = false) => {
    const token = tokenConfig(authToken);
    config = { ...config, ...token };
    return fetch(`${base_url}${url}`, config).then(response => {
      if (response.status < 400) {
        return response.json();
      }

      return ignoreError ? {} : [];
    });
  };

  useEffect(() => {
    if (!user && !!authToken) {
      setIsLoading(true);
      fetcher(getUserURL)
        .then(response => {
          setUser(response);
          setIsLoggedIn(true);
          setIsLoading(false);
        })
        .catch(error => {
          setUser(null);
          setIsLoggedIn(false);
          setAuthToken("");
          localStorage.removeItem("token");
          setIsLoading(false);
        });
    }
  }, [authToken]);

  const onLogout = () => {
    if (isLoggedIn) {
      fetcher(logOutURL).then(response => {
        setUser({});
        setIsLoggedIn(false);
        setAuthToken("");
        localStorage.removeItem("token");
      });
    }
  };

  const childContext = useMemo(() => {
    const useAuthDisclosure = () => {
      let history = useHistory();

      function onOpen(path, headerElem) {
        setIsOpen(true);
        // history.push(`/${path}`);
        if (headerElem) setHeaderElem(headerElem);
        if (path === "login") setIsLogin(true);
        if (path === "signup") setIsLogin(false);
      }

      function onClose() {
        setIsOpen(false);
        // history.goBack();
      }

      return {
        isOpen,
        onOpen,
        onClose,
        isLogin,
        headerElem
      };
    };

    return {
      useAuthDisclosure,
      setAuthToken,
      isLoggedIn,
      isLoading,
      user,
      onLogout,
      fetcher
    };
  }, [isLogin, fetcher, isLoading, user, isLoggedIn, isOpen, headerElem]);

  return (
    <AppContext.Provider value={childContext}>{children}</AppContext.Provider>
  );
};

export { AppProvider, AppContext };
