import { useContext } from "react";
import { AppContext } from "../authentication/AppProvider";

export const useBookingAction = () => {
  const { fetcher } = useContext(AppContext);
  // GET CELEB
  const getCelebrity = slug_url => {
    return fetcher("/api/v1/celebrity/" + slug_url).then(res => res);
  };

  // GET CELEB BOOKINGS
  const getBookings = slug_url => {
    return fetcher("https://002301f7.ngrok.io/api/v1/booking/" + slug_url).then(
      res => res
    );
  };

  const verifyBooking = booking_id => {
    return fetcher(`/api/v1/verify-booking/${booking_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ uuid: booking_id })
    }).then(res => res);
  };

  const acceptBooking = (celeb, booking_id) => {
    return fetcher(`/api/v1/booking/${celeb}/${booking_id}/accept/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(res => res);
  };

  const doReview = (booking_id, review, callback) => {
    return fetcher(`/api/v1/peng/${booking_id}/review/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(review)
    }).then(res => {
      callback();
      return res;
    });
  };

  const completeBooking = (celeb, booking_id) => {
    return fetcher(`/api/v1/booking/${celeb}/${booking_id}/complete/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(res => res);
  };

  const rejectBooking = (celeb, booking_id) => {
    return fetcher(`/api/v1/booking/${celeb}/${booking_id}/decline/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    }).then(res => res);
  };

  return {
    rejectBooking,
    completeBooking,
    doReview,
    acceptBooking,
    verifyBooking,
    getBookings,
    getCelebrity
  };
};
