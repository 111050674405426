import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  Avatar
} from "@chakra-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext, root_url } from "../authentication/AppProvider";
import { useGetSeachResults } from "../celebrity-ui/hooks";
import { useMedia, useScrollYPosition } from "../external-pages/utils/hooks";
import AutoComplete from "../form-elements/AutoComplete";

export const pengPink = root_url + "/static/images/PENG_PINK.png";
export const pengWhite = root_url + "/static/images/PENG_WHITE.png";

const iconProps = {
  size: "16px",
  name: "search",
  color: "pink.400"
};
const inputProps = {
  placeholder: "Type a celeb...",
  fontSize: "md",
  size: "md",
  focusBorderColor: "pink.400"
};

const stickyWrapperProps = (hideBorder, scrollThreshold, isHomePage) => ({
  top: 0,
  bg: isHomePage & !scrollThreshold ? "black" : "white",
  zIndex: "sticky",
  position: isHomePage && !scrollThreshold ? "relative" : "sticky",
  borderBottomWidth: hideBorder ? "none" : "2xs",
  borderColor: isHomePage & !scrollThreshold ? "black" : "gray.200"
});

const buttonProps = (isHomePage, scrollThreshold) => ({
  variant: "link",
  size: "md",
  variantColor: "pink",
  color: isHomePage && !scrollThreshold ? "white" : "pink.500"
});

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto"
};

const Navigation = ({
  children,
  isHomePage,
  hideAutoComplete = false,
  hideLoginButton = false,
  hideSignupButton = false,
  hideBorder = false,
  ...rest
}) => {
  const { useAuthDisclosure, isLoggedIn, user } = useContext(AppContext);
  const { onOpen } = useAuthDisclosure();
  const [query, setQuery] = useState("");
  const { data, hasData } = useGetSeachResults(query);
  const { scrollThreshold, y } = useScrollYPosition(700, 850, true);
  const is_celeb = !!user && !!user.is_celeb;

  let history = useHistory();
  let isMobile = useMedia("(max-width: 420px)");

  let initialLogo = isHomePage ? pengWhite : pengPink;
  const [logo, setLogo] = useState(initialLogo);

  useEffect(() => {
    setLogo(isHomePage && !scrollThreshold ? pengWhite : pengPink);
  }, [y, scrollThreshold, isHomePage]);

  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onOpen: onDrawerOpen
  } = useDisclosure();

  let suggestions = data || [];

  const handleChange = value => {
    setQuery(value);
  };
  const handleLogoClick = () => {
    let openDrawer = isMobile && !hideLoginButton && !hideSignupButton;
    if (openDrawer) {
      onDrawerOpen();
    } else {
      history.push("/c/trending");
    }
  };

  return (
    <>
      <Box {...stickyWrapperProps(hideBorder, scrollThreshold, isHomePage)}>
        <Box {...wrapperProps}>
          <Flex
            py={3}
            px={[5, 6]}
            align="center"
            justify="space-between"
            {...rest}
          >
            <Stack
              isInline
              align="center"
              spacing={5}
              w={["100%", "75%", "80%", "60%"]}
              transition="all 1.2s ease-in-out"
            >
              <Box
                cursor="pointer"
                maxWidth={[168, 168]}
                onClick={handleLogoClick}
              >
                <Image
                  src={logo}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  alt="Peng"
                />
              </Box>

              {!hideAutoComplete &&
                (scrollThreshold ? (
                  <AutoComplete
                    hasLoadedData={hasData}
                    onChange={handleChange}
                    onSuggestionSelected={(_, { suggestion }) => {
                      history.replace(`/${suggestion.slug_url}`);
                    }}
                    options={suggestions}
                    {...{ iconProps, inputProps }}
                  />
                ) : (
                  <Box py={5} w="100%" />
                ))}
            </Stack>
            {children
              ? children
              : !isLoggedIn && (
                  <Stack
                    d={["none", "block"]}
                    isInline
                    align="center"
                    spacing={10}
                  >
                    {!hideLoginButton && (
                      <Button
                        onClick={() => onOpen("login")}
                        {...buttonProps(isHomePage, scrollThreshold)}
                      >
                        Log in
                      </Button>
                    )}
                    {!hideSignupButton && (
                      <Button
                        onClick={() => onOpen("signup")}
                        {...buttonProps(isHomePage, scrollThreshold)}
                      >
                        Sign up
                      </Button>
                    )}
                  </Stack>
                )}

            {isLoggedIn && (
              <Stack d={["none", "block"]} isInline align="center" spacing={10}>
                {is_celeb && (
                  <Button
                    as="a"
                    href="/celebrity"
                    {...buttonProps(isHomePage, scrollThreshold)}
                  >
                    Bookings
                  </Button>
                )}
                <Button {...buttonProps(isHomePage, scrollThreshold)}>
                  Log out
                </Button>
              </Stack>
            )}
          </Flex>
        </Box>
      </Box>

      <MobileDrawer
        {...{
          isDrawerOpen,
          onDrawerClose,
          isLoggedIn,
          hideLoginButton,
          onOpen,
          hideSignupButton
        }}
      />
    </>
  );
};

export default Navigation;

const MobileDrawer = ({
  isDrawerOpen,
  onDrawerClose,
  hideLoginButton,
  isLoggedIn,
  onOpen,
  hideSignupButton
}) => {
  let history = useHistory();
  return (
    <Drawer
      size="xs"
      isOpen={isDrawerOpen}
      placement="left"
      isFullHeight
      onClose={onDrawerClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader maxWidth="144px">
          <Image src={pengPink} w="100%" h="100%" objectFit="cover" />
        </DrawerHeader>

        <DrawerBody fontSize="xl" color="pink.400">
          <Stack spacing={5} mt={3}>
            {!hideLoginButton && !isLoggedIn && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  onOpen("login");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Log in</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}
            {!hideSignupButton && !isLoggedIn && (
              <Stack
                pb={3}
                borderBottomWidth="2xs"
                onClick={() => {
                  onOpen("signup");
                  onDrawerClose();
                }}
                justify="space-between"
                isInline
                align="center"
              >
                <Text>Sign up</Text>
                <Icon name="chevron-right" size="32px" />
              </Stack>
            )}

            <Stack
              onClick={() => {
                history.push("/c/trending");
                onDrawerClose();
              }}
              justify="space-between"
              isInline
              align="center"
            >
              <Text>Home</Text>
              <Icon name="home" size="32px" />
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
