import { Box, Heading, Image, Stack } from "@chakra-ui/core";
import React from "react";
import SignupForm from "../authentication/SignupForm";
import { pengPink } from "./NavigationBar";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "540px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const Signup = props => {
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <Helmet>
        <title>{"Signup"}</title>
        <meta name="description" content={t("Sign up")} />
        <meta name="keywords" content="celebrity,gifts,peng" />
      </Helmet>
      <Box {...wrapperProps}>
        <Stack align="center" spacing={5} mb={8}>
          <Box maxW="168px">
            <Image src={pengPink} />
          </Box>
          <Heading>Create an account</Heading>
        </Stack>
        <SignupForm />
      </Box>
    </Box>
  );
};

export default Signup;
