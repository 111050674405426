import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppContext } from "../authentication/AppProvider";
import ResetPassword from "../authentication/ResetPassword";
import BookingConfirmation from "../bookings/BookingConfirmation";
import FAQ from "../external-pages/FAQ";
import LoadingPage from "../external-pages/LoadingPage";
import Login from "../external-pages/Login";
import Privacy from "../external-pages/Privacy";
import Signup from "../external-pages/Signup";
import Terms from "../external-pages/Terms";
import CelebrityDashboard from "../internal-pages/CelebrityDashboard";

const HomePage = React.lazy(() => import("../external-pages/HomePage"));
const CelebrityProfile = React.lazy(() =>
  import("../celebrity-ui/CelebrityProfile")
);
const BookCelebrity = React.lazy(() => import("../bookings/BookCelebrity"));

let bookingRoutes = [
  {
    path: "/:slug_url/book",
    exact: true,
    Component: BookCelebrity,
    destination: "/booking-confirmed"
  },
  {
    path: "/:slug_url",
    exact: true,
    Component: CelebrityProfile,
    destination: "/book"
  }
];

const BookingFlow = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/faq" component={FAQ} />
      <Route celeb={true} path="/celebrity">
        <React.Suspense fallback={<LoadingPage />}>
          <CelebrityDashboard />
        </React.Suspense>
      </Route>
      <Route exact path="/booking-confirmed">
        <React.Suspense fallback={<LoadingPage />}>
          <BookingConfirmation />
        </React.Suspense>
      </Route>
      />
      {bookingRoutes.map((route, index) => {
        let { Component, destination, path, ...rest } = route;
        return (
          <Route {...rest} path={path} key={index}>
            <React.Suspense fallback={<LoadingPage />}>
              <Component
                toNextPage={slug_url => {
                  history.push(`/${slug_url}${destination}`);
                }}
              />
            </React.Suspense>
          </Route>
        );
      })}
      <Route path="/">
        <React.Suspense fallback={<LoadingPage />}>
          <HomePage />
        </React.Suspense>
      </Route>
    </Switch>
  );
};

export default BookingFlow;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading, isLoggedIn } = useContext(AppContext);

  return (
    <Route {...rest}>
      {props => {
        if (isLoading) {
          return <h2>Loading...</h2>;
        } else {
          console.log({ user });
          console.log({ isLoggedIn });
          if (!isLoggedIn) {
            return <Redirect to="/login" />;
          } else if (isLoggedIn && rest.celeb) {
            if (!!user && !user.is_celeb) {
              return <Redirect to="/" />;
            }
            return <Component {...props} />;
          } else {
            return <Component {...props} />;
          }
        }
      }}
    </Route>
  );
};
