import { Box, Icon, Link, Stack, Text } from "@chakra-ui/core";
import React from "react";

const wrapperProps = { maxW: ["100%", "100%", "100%", "1152px"], mx: "auto" };

const Footer = props => {
  return (
    <Box borderTopWidth="2xs" py={8} bg="gray.800" color="white">
      <Box {...wrapperProps} px={[5, 6]} opacity={0.8}>
        <Stack
          flexDirection={["column", "row"]}
          spacing={[6, 2]}
          justifyItems="space-between"
        >
          <Text flex={1} fontWeight="bold">
            PENG © 2020
          </Text>

          <Stack
            isInline
            spacing={12}
            flexDirection={["column", "row"]}
            flex={1}
          >
            <Stack isInline align="center">
              <Icon name="phone" />
              <Text>+2348039447753</Text>
            </Stack>
            <Stack isInline align="center">
              <Icon name="email" />
              <Text>support@sendpeng.com</Text>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            isInline
            spacing={8}
            justifyContent={["flex-start", "flex-end"]}
            justifyItems="space-between"
          >
            <Link href="/terms">Terms</Link>
            <Link href="/privacy">Privacy</Link>
            <Link href="/faq">FAQ</Link>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
