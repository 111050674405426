// @refresh reset
import { useEffect, useContext } from "react";
import useSWR, { mutate } from "use-swr";
import { AppContext, root_url } from "../authentication/AppProvider";

export const createUrl = (route, query) => {
  // const baseUrl = base_url + route;
  return query ? `${route}/${query}` : route;
};

const getUrl = (path, query) => createUrl(path, query);

// export function usePrefetcher(slug) {
//   const { fetcher } = useContext(AppContext);
//   const helper = callback => {
//     fetcher(getUrl("/celebrity", slug)).then(res => {
//       mutate(getUrl("/celebrity", slug), res);
//     });
//   };
//   return helper;
// }

export function useGetCategories(options = {}) {
  const { fetcher } = useContext(AppContext);
  const { isDependent } = options;
  const url = isDependent ? () => getUrl(`/categories`) : getUrl(`/categories`);

  const { data, ...rest } = useSWR(url, fetcher);

  let result = data || [];
  useEffect(() => {
    if (data) {
      data
        .filter(x => x.count > 0)
        .forEach(x => {
          fetcher(getUrl("/celebrities", x.slug)).then(res => {
            mutate(getUrl("/celebrities", x.slug), res);
          });
        });
    }
  }, [result.length]);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetCelebrities(options = {}) {
  const { fetcher } = useContext(AppContext);
  const { isDependent, category } = options;
  const url = isDependent
    ? () => getUrl("/celebrities", category)
    : getUrl("/celebrities", category);

  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetSeachResults(query) {
  const { fetcher } = useContext(AppContext);
  const url = Boolean(query) && createUrl("/search", query);

  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}

export function useGetCeleb(query) {
  const { fetcher } = useContext(AppContext);
  const url = query && createUrl("/celebrity", query);
  const { data, ...rest } = useSWR(url, fetcher);

  return {
    data,
    hasData: Boolean(data),
    ...rest
  };
}
