import { Box, Grid, Text } from "@chakra-ui/core";
import React from "react";
import CelebrityCard from "./CelebrityCard";
import LoadingCard from "./LoadingCelebrityCard";
let dummy = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const CategoryGrid = ({ data, isError, hasData, loadingArray = dummy }) => {
  return (
    <Box>
      {isError ? (
        <Text>Images failed to load</Text>
      ) : (
        <Grid
          templateColumns={[
            "repeat(2, 1fr)",
            "repeat(auto-fill, minmax(192px, 1fr))"
          ]}
          rowGap={6}
          columnGap={5}
          w="100%"
        >
          {!hasData || data.length === 0
            ? loadingArray.map((_, index) => <LoadingCard key={index} />)
            : data &&
              data.length > 0 &&
              data.map(celebrity => (
                <CelebrityCard {...celebrity} key={celebrity.slug_url} />
              ))}
        </Grid>
      )}
    </Box>
  );
};

export default CategoryGrid;
