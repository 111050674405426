import { Heading, Box, Text } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const FAQ = props => {
  return (
    <PageWrapper>
      <Box bg="gray.50" minH="640px">
        <Box {...wrapperProps}>
          <Heading>Frequently Asked Questions</Heading>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>1. What is PENG?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            PENG is an online community that connects talents and fans
            togethers. It is an online platform that allows people to book and
            pay for personalized shoutout videos from talents or celebrities of
            their choice.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>2. How does it work?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            On the PENG website or app, a user can select from a pool of talents
            and request a personalized shoutout, specifying the name of the
            receiver, the occasion and brief comment about what the video is
            about. If the talent accepts the request , he/she will create and
            send the personalized video as requested.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>3. How do I request for a PENG?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            All you need to do is visit the website (www.sendpeng.com), go to a
            talent’s profile and place an order. The information required from
            you will pop up. Once you fill them and make payment, you are done.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>4. How do I get my PENG after placing an order?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Once the talent has recorded your video, you will be notified via
            email and SMS. You will be able to view, share, and download the
            video from the link we share with you.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>5. How much does a PENG cost?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            The price is set by the talents and it typically ranges between
            N10,000 and N300,000. A good number of the talents have decide to
            donate a large portion of their revenue to charity so indirectly,
            you are contributing to someone out there is in need of something
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>6. Can I share my PENG on social or with friends?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Of course. You are free to share your videos with friends and
            family. Just download the video from the link we share with you (or
            your profile page) and share via WhatsApp, Instagram, email or any
            other channel you wish
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              7. Are there restrictions on the type of PENG that can be created?
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Yes. Videos that relate or involve violence, nude, discriminatory,
            racism, tribalism, hate speech, pornographic, or sexually suggestive
            videos are not allowed. Please read more information about this on
            our complete terms and conditions page
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              8. How long does it take to get a PENG from a celebrity?
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Talents usually have their videos created between 24 – 48 hours. In
            the event that the video is not created within 7 days, the booking
            will be expired and your full payment will be refunded to your
            account after 48 hours
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              9. Can I correct a mistake I made while I was booking a video?
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Provided the video has not been accepted by the talent, you are free
            to make changes to your booking.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              10. Are my videos private to me alone after creation?
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            This is up to you. While you are placing an order for a video, you
            are prompted to confirm if you want your video to be made public or
            private. Based on the information provided, your video will be
            restricted or unrestricted to the public
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>11. Can orders be placed from outside Nigeria?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Yes. Provide your payment method is acceptable by our international
            payment partner, anyone can place an order from anywhere in the
            world
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>12. What payment methods are acceptable on PENG?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            We accept most major debit cards, bank transfers as well as USSD
            transfers (Nigerian users).
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>13. At what point do I get charged for my order?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            Upon booking a talent, your money is held in a virtual wallet
            (escrow account) pending the time the video is created. Once the
            video is created and delivered to you, you will be immediately
            debited. In the event that the video is not created or the order is
            cancelled, your money will be refunded within 48 hours depending on
            your bank.
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>
              14. How can I thank the talent for fulfilling my order?
            </strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            After receiving the video, we encourage fans to write a review about
            the talent on the talents profile page. This serves as an
            appreciation and validated talents participation on the platform.
            Also, you can intentionally capture the moment when your friend or
            family is watching the video made by the talent (we call this
            Expressions). You can upload this to your personal profile and tag
            that talent while you do so
          </Text>
          <br />
          <Text fontSize="16px" color="#F411A8">
            <strong>15. What do you do with my private information?</strong>
          </Text>
          <br />
          <Text fontSize="14px" color="black">
            We only use your personal information to process booking payments,
            provide customer support, and send you information regarding your
            PENG account. We take our customers data very seriously. Please read
            through our privacy policy
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text fontSize="12px" color="black">
            Last Updated: 1st November, 2019
          </Text>
          <br />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default FAQ;
