import { Heading, Box } from "@chakra-ui/core";
import React from "react";
import PageWrapper from "./PageWrapper";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "1152px"],
  mx: "auto",
  px: [5, 6],
  py: [8, 12]
};

const Privacy = props => {
  return (
    <PageWrapper>
      <Box bg="gray.50" minH="640px">
        <Box {...wrapperProps}>
          <Heading>Privacy policy</Heading>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Privacy;
