import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  Divider
} from "@chakra-ui/core";
import React from "react";
import { useGetCeleb } from "../celebrity-ui/hooks";
import { root_url } from "../authentication/AppProvider";
import Navigation from "../external-pages/NavigationBar";
import { NextSteps } from "./BookCelebrity";
import { SimilarCelebs } from "../celebrity-ui/CelebrityProfile/ProfilePage";
import LoadingPage from "../external-pages/LoadingPage";

const wrapperProps = {
  maxW: ["100%", "100%", "100%", "100%", "80%"],
  mx: "auto"
};

const BookingConfirmation = prop => {
  const { data, hasData } = useGetCeleb("iniedo");

  return hasData ? (
    <>
      <Navigation />
      <BackgroundImage {...data} />

      <Box {...wrapperProps} px={[5, 8]} py={[3, 10]}>
        <Grid
          templateColumns={["none", "none", "none", "3fr 2fr"]}
          mt={[8, 8, 8, 6]}
          mb={[12, 12, 12, 10]}
          columnGap={16}
          rowGap={12}
        >
          <RequestDetails {...data} />
          <NextSteps {...data} />
        </Grid>

        {data && data.related > 0 && <SimilarCelebs relate={data.related} />}
      </Box>
    </>
  ) : (
    <LoadingPage />
  );
};

export default BookingConfirmation;

const RequestDetails = ({
  nickname,
  slug_url,
  private_order,
  currency = "₦",
  price
}) => {
  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold">
        Request details
      </Text>
      <Divider my={5} />
      <Stack spacing={5}>
        <Stack spacing={1}>
          <Text fontWeight="bold">Celebrity</Text>
          <Link href={`/${slug_url}`} color="pink.500">
            {nickname}
          </Link>
        </Stack>

        <Flex justify="space-between">
          <Stack spacing={1}>
            <Text fontWeight="bold">Video for</Text>
            <Text color="gray.600">Moyo</Text>
          </Stack>

          <Stack spacing={1}>
            <Text fontWeight="bold">Booked by</Text>
            <Text color="gray.600">Temitope</Text>
          </Stack>
        </Flex>

        <Stack spacing={1}>
          <Text fontWeight="bold">Instructions for {nickname}</Text>
          <Text color="gray.600">
            Please help me wish my mother a very happy birthday. She loves you
            and would be super happy to hear from you.
          </Text>
        </Stack>

        <Text as="i">{`${
          private_order ? "Booked privatedly" : "Booked publicly"
        }`}</Text>

        <Stack spacing={1}>
          <Text>
            Payment: {currency}
            {price}
          </Text>
          <Text>Expires on: June 20</Text>
        </Stack>

        <Stack spacing={1}>
          <Text fontWeight="bold">Delivery information</Text>
          <Text color="gray.600">Email: esantj@gmail.com</Text>
          <Text color="gray.600">Phone: +234812345678</Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const BackgroundImage = ({ picture, nickname, slug_url }) => {
  return (
    <Flex
      h="280px"
      align="center"
      direction="column"
      justifyContent="center"
      justify="center"
      position="relative"
    >
      <Image
        h="100%"
        w="100%"
        bgImage="url(https://i.ibb.co/SfxQmY9/1567770.gif)"
        bgSize="cover"
        bgPos="center"
        backgroundRepeat="no-repeat"
      />
      <Box mx="auto" w={["80%", "40%"]} position="absolute" textAlign="center">
        <Avatar
          src={`${root_url + picture}`}
          name={nickname}
          size="lg"
          showBorder
          borderWidth="xs"
          borderColor="pink.300"
        />
        <Stack mt={5}>
          <Heading size="lg" fontWeight="bold" textTransform="uppercase">
            Your request is booked!
          </Heading>
          <Text opacity={0.8}>
            Your request was sent to{" "}
            <Link color="pink.500" href={`/${slug_url}`}>
              {nickname}
            </Link>
            . You should receive an email shortly
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};
