import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  Text,
  Link
} from "@chakra-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AppContext } from "./AppProvider";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

const AuthModal = ({ isOpen, onClose, headerElem, isLogin }) => {
  const { isLoggedIn } = useContext(AppContext);
  const [isLoginForm, setIsLoginForm] = useState(isLogin);

  useEffect(() => setIsLoginForm(isLogin), [isLogin]);

  const location = useLocation();

  if (isLoggedIn) {
    if (location.pathname === "/login") return <Redirect to="/" />;
    return "";
  }

  let modalHeading = headerElem
    ? headerElem
    : isLoginForm
    ? "Login to PENG"
    : "Signup to PENG";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent py={5} rounded="lg">
        <ModalHeader>{modalHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoginForm ? <LoginForm /> : <SignupForm />}

          {isLoginForm ? (
            <Text mt={8} textAlign="center">
              Don't have an account?{" "}
              <Link color="pink.500" onClick={() => setIsLoginForm(false)}>
                Sign up{" "}
              </Link>
            </Text>
          ) : (
            <Text mt={8} textAlign="center">
              Already have an account?{" "}
              <Link color="pink.500" onClick={() => setIsLoginForm(true)}>
                Log in
              </Link>
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
